import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProjectPage from "./pages/project/ProjectPage";
import ProjectsPage from "./pages/ProjectsPage";
import SettingsPage from "./pages/SettingsPage";
import AuthPage from "./pages/AuthPage";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import firebase from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AuthRoute } from "./auth/AuthRoute";
import { LogInPage } from "./auth/LogInPage";
import { MultiOnboardPage } from "./auth/MultiOnboardPage";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { UserProvider } from "./auth/UserContext";
import { ConfigDataProvider } from "./config/ConfigDataProvider";
import { LandingPage } from "./LandingPage";
import { InvitePage } from "./auth/InvitePage";


export const App = () => {

    return (
        <ChakraProvider theme={theme}>
            {/* Feature flags */}
            <ConfigDataProvider>
                <UserProvider>
                    <AppInner />
                </UserProvider>
            </ConfigDataProvider>
        </ChakraProvider >
    )
}

const AppInner = () => {
    // Do global things here
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/invite/:inviteId' element={<InvitePage />} />
                {/* AUTH */}
                <Route element={<AuthRoute />}>
                    <Route path="login" element={<LogInPage />} />
                    <Route path="onboard" element={<MultiOnboardPage />} />
                </Route>
                <Route element={<ProtectedRoute />}>
                    <Route path='/' element={<ProjectsPage />} />
                    <Route path="/project" element={<ProjectsPage />} />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route path="/project/:id" element={<ProjectPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
