import { Textarea, TextareaProps } from "@chakra-ui/react";
import { FC, useState } from "react";

const AutoResizeTextarea: FC<TextareaProps> = (props) => {
    const [userResized, setUserResized] = useState(false)
    const [startSize, setStartSize] = useState(0)
    return (
        <Textarea 
            {...props} 
            onMouseDown={(event) => {
                if(userResized) {
                    return
                }

                // mark down the size of the textarea when the user started clicking it
                const textarea = event.target as HTMLTextAreaElement
                setStartSize(textarea.clientHeight)
            }}
            onMouseUp={(event) => {
                if(userResized) {
                    return
                }

                // when they are done clicking it, if the height is different, then
                // the user resized the box, and not us from onChange
                const textarea = event.target as HTMLTextAreaElement
                setUserResized(textarea.clientHeight != startSize)
            }}
            onChange={(event) => {
                // once the user resizes the box, we want to keep it at the height that they set,
                // rather than ignoring their preferences and resetting the height to the content
                if(!userResized) {
                    event.target.style.height = event.target.scrollHeight + "px"
                }
                if(!!props.onChange) {
                    props.onChange(event)
                }
            }}
        />
    )
}

export default AutoResizeTextarea