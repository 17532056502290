import {
    useBoolean,
    VStack,
    HStack,
    ButtonGroup,
    Text,
    useToast,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Box,
} from "@chakra-ui/react";
import { CreateCompletionResponse } from "openai";
import { FC, useState } from "react";
import { Prompt, GenParams } from "../../types";
import GenerateButton from "./GenerateButton";
import ParametersPopover from "./ParametersPopover";
import * as service from "../../service"
import OutputCard from "./OutputCard";
import AutoResizeTextarea from "../../components/AutoResizeTextarea";

type PromptViewProps = {
    prompt: Prompt;
    validApiKey: boolean;
    onClick?: () => void
    generate: (template: string, genParams: GenParams) => Promise<CreateCompletionResponse>;
};
const PromptView: FC<PromptViewProps> = ({
    prompt,
    validApiKey,
    onClick,
    generate,
}) => {
    const toast = useToast();
    const [isGenerating, setGenerating] = useBoolean();
    const [outputData, setOutputData] = useState<{ outputs: CreateCompletionResponse[], amount: number }>(
        { 
            outputs: prompt.outputs ?? [], 
            amount: prompt.outputs?.length ?? 0 
        }
    )
    const [template, setTemplate] = useState(prompt.template); // TODO: display to user whether this is saved or not
    const [genParams, setGenParams] = useState<GenParams>(prompt.genParams || {
        maxLen: 500,
        temperature: 0.7,
        nResults: 1
    })

    const outputCards = outputData.outputs.map((output, idx) => <OutputCard key={idx} output={output} maxTokens={genParams.maxLen} />);

    const updateParams = (newParams: {nResults?: number, temperature?: number, maxLen?: number}) => {
        setGenParams({
            maxLen: newParams.maxLen || genParams.maxLen,
            temperature: newParams.temperature || genParams.temperature,
            nResults: newParams.nResults || genParams.nResults
        })
    }

    const handleGenerate = () => {
        if (isGenerating) {
            return;
        }

        const executeGenerate = async () => {
            setGenerating.on();            
            setOutputData({ outputs: [], amount: genParams.nResults })

            await service.updatePrompt({ promptId: prompt.id, template, genParams })

            // we need to save the outputs in this function itself and not just react state
            // because we save the prompt in the finally block w/ the outputs. the function
            // is still in the closure of the render when generate was clicked.
            let outputs: CreateCompletionResponse[] = []
            try {
                const processing: Promise<void>[] = [];
                for (let i = 0; i < genParams.nResults!; i += 1) {
                    processing.push(
                        generate(template, genParams)
                            .then((newOutput) => {
                                outputs = [...outputs, newOutput]
                                // we need to set the whole object each time or otherwise it will
                                // just be based on the initial executeGenerate closure's value, not
                                // the last value set
                                setOutputData({ amount: genParams.nResults, outputs })
                            })
                    );
                }
                await Promise.all(processing);
            } catch (err) {
                throw new Error("failed to generate response from OpenAI", { cause: err })
            } finally {
                setGenerating.off();
                await service.updatePrompt({ promptId: prompt.id, outputs })
            }
        };
        executeGenerate()
            .catch(err => {
                toast({
                    title: "Unexpected error",
                    description: "Check browser console for more info",
                    status: "error",
                    duration: 3000,
                });
                console.log(err)
            })
    };
    return (
        <VStack
            alignItems="flex-start"
            maxHeight="100%"
            pr="1%"
            pb="0.5%"
            onClick={onClick}
        >
            <AutoResizeTextarea
                resize="vertical"
                borderColor="gray.500"
                paddingInline={2}
                placeholder="Your prompt"
                value={template}
                onChange={(event) => setTemplate(event.target.value)}
            />
            <HStack>
                <ButtonGroup isAttached>
                    <GenerateButton
                        validApiKey={validApiKey}
                        templateEmpty={template.trim() === ""}
                        isLoading={isGenerating}
                        nResults={genParams.nResults}
                        onClick={handleGenerate}
                        borderEndEndRadius="0"
                        borderTopRightRadius="0"
                    />
                    <NumberInput
                        onChange={(valueString) => updateParams({ nResults: parseInt(valueString)})}
                        defaultValue={genParams.nResults}
                        colorScheme="brand"
                        min={1}
                        w="20%"
                        size="sm"
                    >
                        <NumberInputField borderLeftWidth={0} borderTopLeftRadius={0} borderBottomLeftRadius={0} />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <ParametersPopover
                        temperature={genParams.temperature}
                        onTemperatureUpdate={(value) => updateParams({ temperature: value })}
                        maxLen={genParams.maxLen}
                        onMaxLenUpdate={(value) => updateParams({ maxLen: value })}
                    />
                </ButtonGroup>
            </HStack>
            <Box pt={1}>
                <Text fontWeight="medium" display="inline" mr="0.5em">Outputs</Text>
                {outputData.amount > 0 && <Text fontFamily="mono" display="inline" fontSize="xs" color="gray.500">{outputData.outputs.length}/{outputData.amount} complete</Text>}
            </Box>
            <VStack w="full">
                {outputCards}
            </VStack>
        </VStack>
    );
};

export default PromptView;

