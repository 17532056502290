import { Flex, Center, Divider, Heading, Box, Text, Container, Progress, VStack, Link as ChakraLink } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";


import { OnboardState, useUserContext } from "./UserContext";
import { on } from "events";
import { ProviderRegistrationForm, ProviderRegistrationFormValues } from "./ProviderRegistrationForm";
import { updateDoc, doc, addDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { FormikHelpers } from "formik";
import firebase from "../firebase";


export const MultiOnboardPage = () => {
  const { user, firebaseUser, signOut, refreshUser } = useUserContext();

  async function onSubmitProviderRegistration({ openAiApiKey }: ProviderRegistrationFormValues,
    helpers: FormikHelpers<ProviderRegistrationFormValues>) {
    // Will write OR update user's openAiApiKey
    if (firebaseUser == null) {
      throw Error('Invalid authentication state: firebaseUser is undefined');
    }

    await setDoc(
      doc(firebase.db, 'users', firebaseUser.uid),
      {
        openAiApiKey,
        complete: true,
        onboardState: OnboardState.ProviderRegistration,
        email: firebaseUser.email, // For admin purposes
        joinDate: serverTimestamp(),
      }
    )
    await refreshUser();
  }

  function renderSwitch(onboardState: OnboardState | undefined) {
    switch (onboardState) {
      case undefined:
        return (<>
          <Box>
            <Heading as='h2' size='lg' mb={1} pt={2}>
              Provider Registration
            </Heading>
            <Text fontSize='sm'>We will need your {' '}
              <ChakraLink color='brand.500' href={'https://beta.openai.com/account/api-keys'} isExternal>OpenAI API key</ChakraLink> to help you send queries.</Text>
          </Box>
          <ProviderRegistrationForm onSubmit={onSubmitProviderRegistration} />
        </>)
      case OnboardState.ProviderRegistration:
        return <Text>Unexpected</Text>;
      default:
        throw Error(`Unexpected onboard state: '${onboardState}'`);
    }
  }

  return (
    <Box>
      <Flex py={3} alignItems={'center'} borderBottomWidth={1}>
        <Box as='button' onClick={() => signOut()} px={5}>
          <CloseIcon w={3} h={3} />
        </Box>
        <Center height='30px' mr={5}>
          <Divider orientation='vertical' />
        </Center>
        <Text>Onboarding</Text>
      </Flex>
      <Flex width={'100%'} justifyContent='center' gap='2'>
        <Container maxW="container.md" px={10} pt={3}>
          {renderSwitch(user?.onboardState)}
        </Container>
      </Flex>
    </Box >
  )
}

