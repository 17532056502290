import { Box, BoxProps } from "@chakra-ui/react"
import { FC, ReactNode } from "react"

type BaseCardProps = {
    children?: ReactNode
}
const BaseCard: FC<BaseCardProps & BoxProps> = ({ children, ...props }) => {
    return (
        <Box 
            p="5%" 
            borderColor="lightgray"
            borderRadius="md"
            borderWidth="1px"
            {...props}
        >
            {children}
        </Box>
    )
}

export default BaseCard