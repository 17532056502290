import { SettingsIcon } from "@chakra-ui/icons";
import {
    Button,
    ButtonGroup,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spacer,
    Text,
    useBoolean,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import CreateNewCard from "../components/CreateNewCard";
import BaseCard from "../components/BaseCard";
import * as dayjs from "../dayjs";
import PageTitle from "../components/PageTitle";
import * as service from "../service";
import firebase from "../firebase";
import { FiLogOut } from "react-icons/fi";
import { useUser } from "../auth/ProtectedRoute";

const createProjectFormSchema = Yup.object({
    name: Yup.string().required("Required"),
});
type CreateProjectFormValues = {
    slug: string;
    name: string;
};

const ProjectsPage = () => {
    const navigate = useNavigate()
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { firebaseUser } = useUser()
    const [projectDocs, _, projectDocsError] = service.useUserProjects()
    if(!!projectDocsError) {
        toast({
            title: "Failed to load projects",
            description: "Check browser console for more info",
            status: "error"
        });
        throw new Error("failed to load projects", { cause: projectDocsError })
    }

    const handleCreateProject = (
        values: CreateProjectFormValues,
        helpers: FormikHelpers<CreateProjectFormValues>
    ) => {
        helpers.setSubmitting(true);
        service.createProject(firebaseUser!.uid, values.name).then((doc) => {
            helpers.setSubmitting(false);
            navigate(`/project/${doc.id}`)
        });
    };

    const projectCards = projectDocs?.map((project) => (
        <BaseCard key={project.id}>
            <Text fontSize="md" fontWeight="semibold">
                {project.name}
            </Text>
            {!!project.created && <Text fontSize="xs">Created {dayjs.fromNow(project.created)}</Text>}
            <ButtonGroup>
                <Button
                    colorScheme="brand"
                    variant="solid"
                    size="sm"
                    mt="10%"
                    as={Link}
                    to={`/project/${project.id}`}
                >
                    Open
                </Button>
                <Button
                    colorScheme="red"
                    variant="outline"
                    size="sm"
                    mt="10%"
                    onClick={() => service.deleteProject(project.id)}
                >
                    Delete
                </Button>
            </ButtonGroup>
        </BaseCard>
    ));

    return (
        <>
            <PageTitle title="Your Projects | Dyno" />
            <VStack alignItems="flex-start">
                <HStack px="1%" pt="1%" w="full">
                    <Text fontSize="2xl" fontWeight="bold">
                        🏠 Your Projects
                    </Text>
                    <Spacer />
                    <Button as={Link} to="/settings">
                        <SettingsIcon />
                    </Button>
                    <Button onClick={firebase.logOut}>
                        <FiLogOut />
                    </Button>
                </HStack>
                <Divider />
                <SimpleGrid px="1%" width="100%" columns={{ base: 1, md: 3, lg: 5 }} gap={2}>
                    <CreateNewCard onCreate={onOpen} text="New Project" />
                    {projectCards}
                </SimpleGrid>
            </VStack>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Project</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={
                            {
                                name: "",
                            } as CreateProjectFormValues
                        }
                        validationSchema={createProjectFormSchema}
                        onSubmit={handleCreateProject}
                    >
                        {({ errors, touched, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                <ModalBody>
                                    <FormControl isInvalid={!!errors.name && touched.name}>
                                        <FormLabel htmlFor="name">Name</FormLabel>
                                        <Field
                                            as={Input}
                                            id="name"
                                            name="name"
                                            placeholder="Project #1"
                                        />
                                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                                    </FormControl>
                                </ModalBody>
                                <ModalFooter justifyContent="flex-start">
                                    <Button
                                        colorScheme="brand"
                                        variant="outline"
                                        type="submit"
                                        isDisabled={isSubmitting}
                                        isLoading={isSubmitting}
                                    >
                                        Create Project
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ProjectsPage;
