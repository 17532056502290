import { onSnapshot, doc, FirestoreDataConverter, WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions, collection } from 'firebase/firestore';
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import firebase from '../firebase';
import { useDocument, useDocumentData, useDocumentOnce } from "react-firebase-hooks/firestore";
import LoadingPage from '../pages/LoadingPage';

// For now, use 'global' dictionary for all feature flags.
// To add a flag, modify the GlobalConfigType and defaultGlobalConfig

type GlobalConfigType = {
  showBanner: boolean;
};

const defaultGlobalConfig = { showBanner: true }

type ConfigContextType = {
  global: GlobalConfigType;
}

export const configContext = createContext<ConfigContextType | null>(null);
export const useConfigContext = () => {
  const context = useContext(configContext);
  if (context === null) {
    throw Error('Use user context within provider');
  }
  return context;
}

export const ConfigDataProvider = (props: PropsWithChildren<{}>) => {
  // Better typing?
  const [globalConfig, globalLoading, globalError] = useDocumentData(
    doc(firebase.db, 'config', 'global')
  );
  if (globalLoading) {
    return <LoadingPage />
  }
  if (globalError || globalConfig == null) {
    console.warn("Unable to retrieve server 'global' config. Using local defaults.");
  }
  // Merge the default dictionary
  const mergedGlobalConfig = { ...defaultGlobalConfig, ...(globalConfig ?? {}) }
  return (
    <configContext.Provider value={{ global: mergedGlobalConfig }} {...props}>
    </configContext.Provider>
  );
}