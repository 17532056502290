import { ButtonProps, ButtonGroup, Tooltip, Button, IconButton, Kbd, Icon, Text, useEventListener } from "@chakra-ui/react";
import { FC } from "react";
import { BiCommand } from "react-icons/bi"
import { MdOutlineKeyboardReturn } from "react-icons/md"

type GenerateButtonProps = { 
    validApiKey: boolean; 
    templateEmpty: boolean; 
    nResults: number;
    onClick?: () => void
}

const GenerateButton: FC<Omit<ButtonProps, "onClick"> & GenerateButtonProps> = ({ validApiKey, templateEmpty, nResults, onClick, ...props }) => {
    const isMacos = window.navigator.userAgent.toLowerCase().includes("mac")

    let message: string | null = null;
    if (!validApiKey) {
        message = "You do not have an API key configured";
    } else if (templateEmpty) {
        message = "You have not written any prompt content";
    }

    const disabled = props.isDisabled || !!message
    const dispatchClick = () => {
        if(disabled) {
            return
        }

        onClick?.()
    }

    useEventListener("keydown", event => {
        if(event.key != "Enter" ) {
            return
        }
        if((isMacos && event.metaKey) || event.ctrlKey) {
            dispatchClick()
        }
    })

    return (
        <Tooltip hasArrow label={message}>
            <Button 
                colorScheme="brand" 
                size="sm" 
                isDisabled={disabled} 
                onClick={dispatchClick}
                {...props}
            >
                Generate
                {isMacos
                    ? <>
                        <Icon as={BiCommand} ml="1em" fontSize="xs" color="gray.300" />
                        <Icon as={MdOutlineKeyboardReturn} fontSize="sm" color="gray.300" />
                    </>
                    : <Text ml="1em" fontWeight="md" fontSize="2xs" color="gray.300">Ctrl+Enter</Text>
                }
            </Button>
        </Tooltip>
    );
};

export default GenerateButton;

