import { FormControl, FormLabel, Input, FormErrorMessage, Button, VStack, Box } from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { string } from 'yup'
import * as Yup from "yup";
import { useUserContext } from './UserContext';
import { doc, updateDoc } from 'firebase/firestore';
import firebase from '../firebase';
// TODO: Enable other LLM providers.

const providerRegistrationFormSchema = Yup.object({
  openAiApiKey: Yup.string().required("Required"),
});

export type ProviderRegistrationFormValues = {
  openAiApiKey: string;
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export type ProviderRegistrationFormProps = {
  userOpenAiApiKey?: string;
  onSubmit: (values: ProviderRegistrationFormValues, helpers: FormikHelpers<ProviderRegistrationFormValues>) => Promise<void>
}

export const ProviderRegistrationForm = ({ userOpenAiApiKey, onSubmit }: ProviderRegistrationFormProps) => {
  // TODO: create an auth level context?

  return (
    <Box pt={2}>
      <Formik
        initialValues={
          {
            openAiApiKey: userOpenAiApiKey ?? '',
          } as ProviderRegistrationFormValues
        }
        validationSchema={providerRegistrationFormSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, handleSubmit, isSubmitting, handleChange, values }) => (
          <Form onSubmit={handleSubmit}>
            <VStack spacing={4} align="flex-start">
              <FormControl isInvalid={!!errors.openAiApiKey}>
                <FormLabel htmlFor="openAiApiKey">OpenAI API Key</FormLabel>
                <Input
                  name="openAiApiKey"
                  placeholder="sk-..."
                  onChange={handleChange}
                  value={values.openAiApiKey}
                />
                <FormErrorMessage>{errors.openAiApiKey}</FormErrorMessage>
              </FormControl>
              <Button
                colorScheme="brand"
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                loadingText='Submitting'
              >
                Continue
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
