import { CloseIcon } from "@chakra-ui/icons"
import { HStack, IconButton, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react"
import { FC, ReactNode } from "react"

type Tab = {
    title: string,
    content: ReactNode
}

type SplitProps = {
    tabs: Tab[],
    activeIndex: number,
    isActive: boolean,
    onClick: () => void,
    onSelect: (index: number) => void,
    onClose: (index: number) => void,
}

const Split: FC<SplitProps> = ({ tabs, isActive, activeIndex, onClick, onSelect, onClose }) => {
    return (
        <Tabs 
            index={activeIndex} 
            w="full"
            h="full"
            overflowY="scroll"
            onClick={onClick} 
            onChange={onSelect}
            borderRightWidth="1px"
            borderColor="gray.200"
            px="0"
        >
            <TabList overflowX="auto" overflowY="hidden" style={{ scrollbarWidth: "thin" }}>
                {tabs.map((tab, idx) => 
                    <Tab 
                        color={isActive ? "inherit" : "gray.800 !important"} 
                        fontWeight={isActive && activeIndex == idx ? "semibold" : "normal"} 
                        key={idx}
                    >
                        <HStack alignItems="center">
                            <Text whiteSpace="nowrap">{tab.title}</Text>
                            <IconButton 
                                aria-label="Close tab" 
                                variant="ghost" 
                                size="xs" 
                                icon={<CloseIcon fontSize="xs" />} 
                                onClick={(event) => {
                                    event.stopPropagation()
                                    onClose(idx)
                                }} 
                            />
                        </HStack>
                    </Tab>
                )}
            </TabList>
            <TabPanels>
                {tabs.map((tab, idx) => <TabPanel key={idx}>{tab.content}</TabPanel>)}
            </TabPanels>
        </Tabs>
    )
}

export default Split