import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { EditableProps, useEditableControls, useColorModeValue, ButtonGroup, IconButton, Editable, EditablePreview, HStack, Input, EditableInput } from "@chakra-ui/react";
import { FC } from "react";

const EditableControls = () => {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps } = useEditableControls();
    const greenCheckColor = useColorModeValue("green.500", "green.500");
    const redCrossColor = useColorModeValue("red.500", "red.500");
    if (isEditing) {
        return (
            <ButtonGroup>
                <IconButton
                    variant="link"
                    aria-label="save"
                    size="sm"
                    icon={<CheckIcon />}
                    _hover={{ color: greenCheckColor }}
                    {...getSubmitButtonProps()}
                />
                <IconButton
                    variant="link"
                    aria-label="cancel"
                    size="sm"
                    icon={<CloseIcon />}
                    _hover={{ color: redCrossColor }}
                    {...getCancelButtonProps()}
                />
            </ButtonGroup>
        );
    }
    return null;
};

const EditableName: FC<{ name: string, onRename: (name: string) => void } & EditableProps> = ({ name, onRename, ...props }) => {
    const grayEditableColor = useColorModeValue("gray.100", "gray.700");
    return (
        <Editable defaultValue={name} onSubmit={onRename} {...props}>
            <EditablePreview
                py={1}
                px={1}
                _hover={{ background: grayEditableColor }}
            />
            <HStack>
                <Input as={EditableInput} />
                <EditableControls />
            </HStack>
        </Editable>
    )
}

export default EditableName