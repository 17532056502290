// original file stolen from https://raw.githubusercontent.com/lukasbach/chakra-ui-contextmenu,
// has modifcations on top of it

import * as React from 'react';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useEventListener, Portal, Menu, MenuButton, PortalProps, MenuButtonProps, MenuProps, MenuListProps, MenuList, MenuItemProps, MenuItem } from '@chakra-ui/react';
import { Variants } from 'framer-motion';

// overrides for the framer motion variants for the MenuList
const customMotionVariants: Variants = {
    initial: {
        opacity: 0
    },
    enter: {
      visibility: "visible",
      opacity: 1,
      transition: {
        duration: 0.03,
      },
    },
    exit: {
      transitionEnd: {
        visibility: "hidden",
      },
      opacity: 0,
      transition: {
        duration: 0.04,
      },
    },
}

// wrapper for MenuItem to apply universal styles for context menus
export const ContextMenuItem: React.FC<MenuItemProps> = (props) => {
    return (
        <MenuItem
            transition="none"
            {...props}
        >
            {props.children}
        </MenuItem>
    )
}

// wrapper for MenuList to apply universal styles and custom framer motion config
export const ContextMenuItemList: React.FC<MenuListProps> = (props) => {
    return (
        <MenuList 
            py="0"
            motionProps={{ variants: customMotionVariants }}
            onContextMenu={(event) => event.preventDefault()} 
            {...props}
        >
            {props.children}
        </MenuList>
    )
}

export interface ContextMenuProps<T extends HTMLElement> {
  renderMenu: () => JSX.Element | null;
  children: (ref: MutableRefObject<T | null>) => JSX.Element | null;
  menuProps?: Omit<MenuProps, 'children'> & { children?: React.ReactNode };
  portalProps?: Omit<PortalProps, 'children'> & { children?: React.ReactNode };
  menuButtonProps?: MenuButtonProps;
}

export function ContextMenu<T extends HTMLElement = HTMLElement>(props: ContextMenuProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [isDeferredOpen, setIsDeferredOpen] = useState(false);
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const targetRef = useRef<T>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsRendered(true);
        setTimeout(() => {
          setIsDeferredOpen(true);
        });
      });
    } else {
      setIsDeferredOpen(false);
      const timeout = setTimeout(() => {
        setIsRendered(isOpen);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  useEventListener('contextmenu', e => {
    if (targetRef.current?.contains(e.target as any) || e.target === targetRef.current) {
      e.preventDefault();
      setIsOpen(true);
      setPosition([e.pageX + 2, e.pageY - 10]);
    } else {
      setIsOpen(false);
    }
  });

  useEventListener("keydown", event => {
    if(event.key == "Escape") {
        setIsOpen(false)
    }
  })

  const onCloseHandler = useCallback(() => {
    props.menuProps?.onClose?.();
    setIsOpen(false);
  }, [props.menuProps?.onClose, setIsOpen]);

  return (
    <>
      {props.children(targetRef)}
      {isRendered && (
        <Portal {...props.portalProps}>
          <Menu isOpen={isDeferredOpen} gutter={0} {...props.menuProps} onClose={onCloseHandler}>
            <MenuButton
              aria-hidden={true}
              w={1}
              h={1}
              style={{
                position: 'absolute',
                left: position[0],
                top: position[1],
                cursor: 'default',
              }}
              {...props.menuButtonProps}
            />
            {props.renderMenu()}
          </Menu>
        </Portal>
      )}
    </>
  );
}