import { Box, Button, Flex, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { BsGoogle } from "react-icons/bs";
import PageTitle from "../components/PageTitle";
import { useUserContext } from "./UserContext";

export const LogInPage = () => {
  const { logIn } = useUserContext();
  return (
    <>
      <PageTitle title="Sign In" />
      <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
        <VStack>
          <Box width="4em">
            <Image src="/logo.png" alt="Dyno Dino" />
          </Box>
          <Text fontWeight="semibold" fontSize="3xl">Log in to your account</Text>
          <Text color="gray.600" fontSize="sm">Don't have an account? Let's get started!</Text>
          <Box w="full">
            <Button 
              mt="2" 
              variant="solid" 
              colorScheme="brand" 
              leftIcon={<Icon as={BsGoogle} color="white" fontSize="xl" mr="0.25em" />} 
              w="full" 
              onClick={() => logIn()}
            >Continue with Google</Button>
          </Box>
        </VStack>
      </Flex>
    </>
  )
}

