import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";
import {
    Button,
    Container,
    Divider,
    HStack,
    Spacer,
    Text,
    VStack,
    Link as ChakraLink,
    Input,
    InputGroup,
    useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import * as service from "../service";
import { useUser } from "../auth/ProtectedRoute";

const SettingsPage = () => {
    const toast = useToast()
    const { user, refreshUser } = useUser();
    const [isSaving, setSaving] = useState(false);
    const [newApiKey, setNewApiKey] = useState<string>(user.openAiApiKey);
    const [isValidKey, setIsValidKey] = useState(true)

    //
    // check current api key on load
    useEffect(() => {
        const checkValidApiKey = async () => {
            const isValid = await service.verifyOpenAiApiKey(user.openAiApiKey)
            if (!isValid) {
                toast({
                    title: "Invalid API key",
                    status: "error",
                    duration: 3000
                })
                setSaving(false)
                return false
            }
            return true
        }
        checkValidApiKey().then(setIsValidKey)
    }, [])

    const onApiKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewApiKey(event.target.value);
    };

    const handleSave = async () => {
        try {
            setSaving(true)
            const isValid = await service.verifyOpenAiApiKey(newApiKey)
            if (!isValid) {
                toast({
                    title: "Save failed",
                    description: "Invalid API key",
                    status: "error",
                    duration: 3000
                })
                setSaving(false)
                setIsValidKey(false)
                return
            }

            service.setApiKey(user.id, newApiKey).then(() => {
                toast({
                    title: "Saved",
                    status: "success",
                    duration: 3000
                })
                setSaving(false);
                setIsValidKey(true)
                refreshUser()
            });
        } catch (error) {
            console.log(error)
            toast({
                title: "Error saving API key",
                status: "error",
                duration: 3000
            })
        }
    }

    return (
        <>
            <PageTitle title="Settings | Dyno" />
            <VStack alignItems="flex-start">
                <HStack px="1%" pt="1%" w="full">
                    <Button variant="ghost" pl="0" pr="0" as={Link} to="/projects">
                        <ArrowBackIcon fontSize="xl" />
                    </Button>
                    <Text fontSize="2xl" fontWeight="bold">
                        🛠️ Settings
                    </Text>
                    <Spacer />
                </HStack>
                <Divider />
                <HStack justifyContent="center" w="full" pt={5}>
                    <Container w="75%">
                        <HStack>
                            <VStack alignItems="flex-start">
                                <Text fontSize="l" fontWeight="semibold">
                                    OpenAI API Key
                                </Text>
                                <Text>
                                    Your API key is how Dyno interacts with OpenAI. To get
                                    an API key, create one on the{" "}
                                    <ChakraLink
                                        href="https://beta.openai.com/account/api-keys"
                                        color="brand.500"
                                        isExternal
                                    >
                                        OpenAI API Keys page
                                    </ChakraLink>
                                    .
                                </Text>
                                <InputGroup>
                                    <Input
                                        isInvalid={!isValidKey}
                                        placeholder="Your API Key"
                                        fontFamily="mono"
                                        onChange={onApiKeyChange}
                                        value={newApiKey} 
                                    />
                                </InputGroup>
                                <Button onClick={handleSave} disabled={(newApiKey === user.openAiApiKey) || isSaving}>Save</Button>
                            </VStack>
                        </HStack>
                    </Container>
                </HStack>
            </VStack>
        </>
    );
};

export default SettingsPage;

