import { AddIcon } from "@chakra-ui/icons"
import { Button, Text, VStack } from "@chakra-ui/react"
import { FC, useState } from "react"
import BaseCard from "./BaseCard"

type CreateNewCardProps = {
    onCreate: () => void,
    text: string,
}
const CreateNewCard: FC<CreateNewCardProps> = ({ onCreate, text }) => {
    const [hovering, setHovering] = useState(false) // hold state of box hover to propogate to children
    return (
        <BaseCard 
            _hover={{ background: "gray.100", cursor: "pointer" }} 
            transitionProperty="common" 
            transitionDuration="normal" 
            onMouseOver={() => setHovering(true)} 
            onMouseOut={() => setHovering(false)} 
            onClick={onCreate}
        >
            <VStack h="full" justifyContent="center"> 
                <Button borderRadius="60px" p="0.5em 0.6em" colorScheme="brand" background={hovering ? "brand.600" : "brand.500"}>
                    <AddIcon fontSize="l" />
                </Button>
                <Text fontWeight="semibold">{text}</Text>
            </VStack>
        </BaseCard>
    )
}

export default CreateNewCard