import { AddIcon, ArrowBackIcon, Icon } from "@chakra-ui/icons";
import {
    HStack,
    VStack,
    Box,
    Text,
    Button,
    Divider,
    SkeletonText,
    Flex,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Prompt } from "../../types";
import * as service from "../../service";
import PromptCard from "./PromptCard";
import PromptView from "./PromptView";
import Split from "./Split";
import PageTitle from "../../components/PageTitle";
import EditableName from "../../components/EditableName";
import { useUser } from "../../auth/ProtectedRoute";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { collection, doc, query, where } from "firebase/firestore";
import firebase from "../../firebase";
import LoadingPage from "../LoadingPage";
import { projectConverter, promptConverter } from "../../service";
import { HiCursorClick } from "react-icons/hi"
import { ContextMenu, ContextMenuItem, ContextMenuItemList } from "../../components/ContextMenu";

type SplitType = {
    activePrompt: string,
    prompts: string[]
}

const ProjectPage = () => {
    const { id: projectId } = useParams();
    const [isValidApiKey, setIsValidApiKey] = useState(true)

    const { user, firebaseUser } = useUser();
    const [promptDocs, promptDocsLoading, promptDocsError] = useCollectionData(
        query(
            collection(firebase.db, "prompts"),
            where("projectId", "==", projectId),
            where('uid', '==', firebaseUser.uid)
        ).withConverter(promptConverter)
    );

    const [projectDoc, projectDocLoading, projectDocError] = useDocumentData(
        doc(firebase.db, "projects", projectId ?? "").withConverter(projectConverter)
    );

    const [splits, setSplits] = useState<SplitType[]>([]);
    const [focusedSplit, setFocusedSplit] = useState<number | null>(null); // index of focused split

    const selectPrompt = useCallback(
        (prompt: Prompt) => {
            if (splits.length === 0) {
                setSplits([
                    { activePrompt: prompt.id, prompts: [prompt.id] },
                ]);
                setFocusedSplit(0);
                return;
            }

            if (focusedSplit == null) {
                throw new Error("no split is marked as focused");
            }

            let updatedActiveSplit;
            if (splits[focusedSplit].prompts.includes(prompt.id)) {
                updatedActiveSplit = {
                    activePrompt: prompt.id,
                    prompts: splits[focusedSplit].prompts,
                };
            } else {
                updatedActiveSplit = {
                    activePrompt: prompt.id,
                    prompts: [
                        ...splits[focusedSplit].prompts,
                        prompt.id,
                    ],
                };
            }

            setSplits([
                ...splits.slice(0, focusedSplit),
                updatedActiveSplit,
                ...splits.slice(focusedSplit + 1),
            ]);
        },
        [splits, focusedSplit]
    )

    //
    // verify api key on mount
    useEffect(() => {
        service.verifyOpenAiApiKey(user.openAiApiKey).then(setIsValidApiKey)    
    }, [])

    // stores the prompt id that we should open when prompts reload. we use this to facilitate opening a prompt
    // when it is created since we need to wait for promptDocs to refresh before we can display it.
    const [promptIdToOpen, setPromptIdToOpen] = useState<string | null>(null)
    useEffect(() => {
        if(promptIdToOpen == null) {
            return
        }

        const prompt = promptDocs?.find(prompt => prompt.id == promptIdToOpen)
        if(!prompt) { 
            return
        }

        selectPrompt(prompt)
        setPromptIdToOpen(null)
    }, [promptDocs, promptIdToOpen])

    if (promptDocsError || projectDocError) {
        throw Error('Error retreiving project / prompt info', { cause: promptDocsError || projectDocError });
    }

    if (projectDocLoading || promptDocsLoading) {
        return <LoadingPage />
    }

    if (projectDoc == null || promptDocs == null) {
        return <Text>404 not found.</Text>
    }

    const sortedPromptDocs = promptDocs.sort((a, b) => a.name.localeCompare(b.name));
    const promptCardContainer = 
        <VStack w="full">
            {sortedPromptDocs.map((promptDoc) => {
                return (
                    <PromptCard
                        key={promptDoc.id}
                        prompt={promptDoc}
                        selected={focusedSplit != null && splits[focusedSplit].activePrompt == promptDoc.id}
                        onSplit={(prompt) => {
                            setSplits([
                                ...splits,
                                { activePrompt: prompt.id, prompts: [prompt.id] },
                            ]);
                            setFocusedSplit(splits.length);
                        }}
                        onSelect={selectPrompt}
                        onDuplicate={(prompt) =>
                            service
                                .createPrompt({
                                    uid: projectDoc?.uid ?? "",
                                    projectId: prompt.projectId,
                                    name: `Copy of ${prompt.name}`,
                                    template: prompt.template,
                                })
                                .then(prompt => setPromptIdToOpen(prompt.id))
                        }
                        onDelete={(prompt) => {
                            // remove the prompt from any splits that are currently visible
                            // this code is pretty similar to the onClose function of PromptView...
                            // we should refactor to reduce redudnancy at some point
                            const newSplits: SplitType[] = []
                            splits.forEach(split => {
                                const promptIdx = split.prompts.indexOf(prompt.id)
                                if (promptIdx == -1) {
                                    // if not in this split, ignore any changes
                                    newSplits.push(split)
                                    return
                                }

                                const newPrompts = split.prompts.filter(id => id != prompt.id)
                                if (newPrompts.length == 0) {
                                    // prompts is now empty, so remove the split
                                    return
                                }

                                let newActivePrompt;
                                if (split.activePrompt != prompt.id) {
                                    newActivePrompt = split.activePrompt
                                } else if (promptIdx == 0) {
                                    newActivePrompt = split.prompts[1]
                                } else {
                                    newActivePrompt = split.prompts[promptIdx - 1]
                                }

                                newSplits.push({
                                    activePrompt: newActivePrompt,
                                    prompts: newPrompts,
                                })
                            })

                            // set focused to null if no more splits
                            if (newSplits.length === 0) 
                                setFocusedSplit(null)
                            else if (focusedSplit === newSplits.length) 
                                setFocusedSplit(newSplits.length-1)

                            setSplits(newSplits)

                            if (!projectId)
                                throw new Error("no projectId set")

                            service
                                .deletePrompt(prompt.id, projectId)
                        }}
                        onRename={(prompt, name) => {
                            service
                                .updatePrompt({ promptId: prompt.id, name })
                        }}
                    />
                );
            })}
        </VStack>

    const splitViews = splits.map((split, idx) => (
        <Split
            key={idx}
            activeIndex={split.prompts.indexOf(split.activePrompt)}
            isActive={focusedSplit == idx}
            onClick={() => setFocusedSplit(idx)}
            onSelect={(tabIdx) => {
                setSplits([
                    ...splits.slice(0, idx),
                    {
                        activePrompt: splits[idx].prompts[tabIdx],
                        prompts: splits[idx].prompts,
                    },
                    ...splits.slice(idx + 1),
                ]);
            }}
            onClose={(tabIdx) => {
                // TODO: refactor the logic using immer
                const split = splits[idx];
                const newPrompts = split.prompts.filter((_, idx) => idx !== tabIdx);
                if (newPrompts.length === 0) {
                    setSplits([...splits.slice(0, idx), ...splits.slice(idx + 1)]);
                    if(splits.length - 1 == 0) {
                        // nothing left to view, so set to null
                        setFocusedSplit(null)
                    } else {
                        // update focus to another split
                        setFocusedSplit(idx == splits.length - 1 ? idx - 1 : idx)
                    }
                } else {
                    let newActivePrompt;
                    if (split.activePrompt !== split.prompts[tabIdx]) {
                        newActivePrompt = split.activePrompt;
                    } else if (tabIdx === 0) {
                        newActivePrompt = split.prompts[1];
                    } else {
                        newActivePrompt = split.prompts[tabIdx - 1];
                    }

                    setSplits([
                        ...splits.slice(0, idx),
                        {
                            activePrompt: newActivePrompt,
                            prompts: newPrompts,
                        },
                        ...splits.slice(idx + 1),
                    ]);
                }
            }}
            tabs={split.prompts.map((promptId: string) => {
                // TODO: Retrieve prompt
                const prompt = promptDocs.find((x) => x.id === promptId);
                if (!prompt) {
                    throw new Error("desync between split prompts and available prompts - " + promptId + " is not in data " + JSON.stringify(promptDocs))
                }

                return {
                    title: prompt.name,
                    content: (
                        <PromptView
                            key={prompt.id}
                            prompt={prompt}
                            validApiKey={isValidApiKey}
                            generate={async (template, genParams) => {
                                if (!projectDoc) {
                                    throw new Error("no project yet... what??");
                                }

                                const completion = await service.generateOpenAiCompletion(
                                    user.openAiApiKey,
                                    template,
                                    genParams
                                );
                                return completion;
                            }}
                        />
                    ),
                }
            })}
        />
    ));

    const handleCreatePrompt = () => {
        if (!projectDoc) {
            throw new Error("no project doc");
        }

        service
            .createPrompt({
                uid: projectDoc.uid,
                projectId: projectDoc.id,
                name: "Untitled",
            })
            .then(prompt => setPromptIdToOpen(prompt.id))
    };

    return (
        <Box h="100vh">
            {!isValidApiKey &&
                <Alert status="error" w="full" justifyContent="center">
                    <AlertIcon />
                    <AlertTitle>OpenAI API key invalid!</AlertTitle>
                    <AlertDescription>
                        To use Dyno, you need to update your API key in Settings.
                    </AlertDescription>
                    <Button as={Link} to="/settings" ml="1em" px="2" py="1" height="min-content" colorScheme="red">
                        Take me there
                    </Button>
                </Alert>
            }
            <PageTitle title={(projectDoc?.name ?? "Project") + " | Dyno"} />
            <VStack h="full" spacing={0}>
                <HStack
                    w="full"
                    alignItems="center"
                    h="4em" /* prevent height from changing when editing project name */
                >
                    <Button variant="ghost" ml={2} px={0} py={2} as={Link} to="/projects">
                        <ArrowBackIcon fontSize="2xl" />
                    </Button>
                    {!!projectDoc ? (
                        <EditableName name={projectDoc.name} onRename={(name) => {
                            if (!projectDoc) return;
                            service.updateProject({ projectId: projectDoc.id, name });
                        }} fontSize="2xl" fontWeight="bold" />
                    ) : (
                        <SkeletonText fontSize="2xl" noOfLines={1} w="15em" />
                    )}
                </HStack>
                <Divider />
                <Flex flexDirection="row" w="full" h="full" minWidth="0">
                    <VStack h="full" w="15%" borderRightWidth="1px" borderColor="gray.200">
                        {promptCardContainer}
                        <ContextMenu<HTMLDivElement>
                            renderMenu={() => (
                                <ContextMenuItemList>
                                    <ContextMenuItem
                                        icon={<AddIcon fontSize="xs" />}
                                        fontSize="xs"
                                        onClick={handleCreatePrompt}
                                    >
                                        New Prompt
                                    </ContextMenuItem>
                                </ContextMenuItemList>
                            )}
                        >
                            {(ref) => 
                                <div ref={ref} style={{ height: "100%", width: "100%" }}>
                                    {promptDocs.length == 0 && 
                                        <VStack pt="5">
                                            <Icon as={HiCursorClick} fontSize="xl" />
                                            <Text fontSize="sm" textAlign="center">Right Click to Create Prompt</Text>
                                        </VStack>
                                    }
                                </div>
                            }
                        </ContextMenu>
                    </VStack>
                    <Flex flexDirection="row" h="full" w="85%">
                        {
                            splits.length != 0
                                ? splitViews
                                :
                                <VStack w="100%" h="50%" justifyContent="center" alignItems="center">
                                    <Text fontSize="xl" fontWeight="bold">
                                        Welcome!
                                    </Text>
                                    <Text fontSize="l">Select a Prompt to Begin</Text>
                                </VStack>
                        }
                    </Flex>
                </Flex>
            </VStack>
        </Box>
    );
};

export default ProjectPage;
