import { User } from "firebase/auth";
import { ReactNode } from "react";
import { UserType } from "./UserContext";

export const computeAuthRoute = (firebaseUser: User | null, user: UserType | null) => {
  // Where a user should be routed?
  if (firebaseUser == null) {
    // Not logged in
    return '/login';
  } else if (user == null || !user.complete) { // firebaseUser must not be null!
    // Not onboarded
    return '/onboard';
  } else {
    // User fully onboarded!
    return null;
  }
}

export type WrapperRouteType = (params: { children: ReactNode }) => JSX.Element

export const DEFAULT_PROTECTED_PAGE = '/';
