import { initializeApp } from "firebase/app";
import {
    doc,
    query,
    where,
    collection,
    getFirestore,
    getDocs,
    setDoc,
} from "firebase/firestore";
import { signOut, getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const prodConfig = {
    apiKey: "AIzaSyDn4j5sVo06djx9_kMvROxXbP2zNAN7BYQ",
    authDomain: "promptly-prod-4a0b2.firebaseapp.com",
    projectId: "promptly-prod-4a0b2",
    storageBucket: "promptly-prod-4a0b2.appspot.com",
    messagingSenderId: "302144212331",
    appId: "1:302144212331:web:885c68dd975f5d8e73d02e",
    measurementId: "G-YEQFW684HK",
};

const devConfig = {
    apiKey: "AIzaSyAv96KIXFi0SIWM2Veiy-2sV9UmmglHIf0",
    authDomain: "promptly-babee.firebaseapp.com",
    projectId: "promptly-babee",
    storageBucket: "promptly-babee.appspot.com",
    messagingSenderId: "1042233905594",
    appId: "1:1042233905594:web:a210639d84feffbbcd4d83",
    measurementId: "G-SVQPVJS6HW"
};

const activeConfig = process.env.NODE_ENV === "production" ? prodConfig : devConfig;
const app = initializeApp(activeConfig);

console.log(`NODE_ENV (${process.env.NODE_ENV}): ${activeConfig.projectId}`)
const auth = getAuth();
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const userRef = collection(db, "users");

const signInWithGoogle = async () => {
    try {
        const response = await signInWithPopup(auth, googleProvider);
        const user = response.user;
        const q = query(userRef, where("uid", "==", user.uid));
        const snap = await getDocs(q);
        if (snap.docs.length === 0) {
            await setDoc(doc(userRef, user.uid), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
                apiKey: "",
            });
        }
    } catch (error) {
        console.log(error);
    }
};

const logOut = () => {
    signOut(auth);
};

// TODO: export const instead of default!
const firebase = {
    auth,
    db,
    signInWithGoogle,
    logOut,
};
export default firebase;
