import React, { useEffect, useId, useState } from 'react'
import { Box, Button, Flex, Icon, Image, Text, useBoolean, useToast, VStack } from '@chakra-ui/react'
import { useParams } from 'react-router';
import { useDocumentData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import firebase from '../firebase';
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { LogInPage } from './LogInPage';
import { OnboardState, useUserContext } from './UserContext';
import { inviteConverter, verifyOpenAiApiKey } from '../service';
import { Navigate, useNavigate } from 'react-router-dom';
import { sleep } from '../dayjs';
import LoadingPage from '../pages/LoadingPage';
import { BsGoogle } from 'react-icons/bs';
import PageTitle from '../components/PageTitle';

export const InvitePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast()

  const [verifiedApiKey, setVerifiedApiKey] = useState<string | null>(null)

  const inviteId = params.inviteId ?? '';
  const { logIn, refreshUser, loading: userLoading, user, firebaseUser } = useUserContext();

  const [isVerifying, setIsVerifying] = useBoolean();

  // Invite doc serializer
  const [inviteDoc, inviteDocLoading, inviteDocError] = useDocumentDataOnce(
    doc(firebase.db, 'invites', inviteId).withConverter(inviteConverter),
  )
  
  const getVerifiedInvite = async () => {
    const isVerified = inviteDoc != null && inviteDoc !== undefined && await verifyOpenAiApiKey(inviteDoc.openAiApiKey)
    if (!isVerified)
      return null
    return inviteDoc.openAiApiKey
  }

  //
  // wait for invite load then verify and redirect if invalid
  useEffect(() => {
    if (inviteDocLoading) return
    setIsVerifying.on()
    getVerifiedInvite().then((apiKey) => {
      if (apiKey == null) {
        console.log("not valid")
        toast({
            title: "Invalid invite link",
            status: "error",
            duration: 3000,
        });
        navigate("/login")
      } else {
        setVerifiedApiKey(apiKey)
      }
      setIsVerifying.off()
    })
  }, [inviteDocLoading])

  // 
  // wait for user auth then claim invite and set user obj
  useEffect(() => {
    if (firebaseUser && !user && verifiedApiKey != null) {
      // claim the invite
      updateDoc(
        doc(firebase.db, 'invites', inviteId),
        { claimedBy: firebaseUser.uid }
      )
        
      // set user
      setDoc(doc(firebase.db, 'users', firebaseUser.uid),
        {
          openAiApiKey: verifiedApiKey,
          complete: true,
          onboardState: OnboardState.ProviderRegistration,
          email: firebaseUser.email, // For admin purposes
          joinDate: serverTimestamp(),
        }
      ).then(() => refreshUser())
    }
  }, [firebaseUser])

  if (userLoading || inviteDocLoading || isVerifying)
    return <LoadingPage />

  if (user) 
    return <Navigate to={"/projects"} replace />

  return (
    <>
      <PageTitle title="Sign In" />
      <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
        <VStack>
          <Box width="4em">
            <Image src="/logo.png" alt="Dyno Dino" />
          </Box>
          <Text fontWeight="semibold" fontSize="3xl">{inviteDoc?.name != null ? `Welcome ${inviteDoc?.name}!` : "Welcome!"}</Text>
          <Text color="gray.600" fontSize="sm">We're excited to have you here</Text>
          <Box w="full">
            <Button 
              mt="2" 
              variant="solid" 
              colorScheme="brand" 
              leftIcon={<Icon as={BsGoogle} color="white" fontSize="xl" mr="0.25em" />} 
              w="full" 
              onClick={logIn}
            >Activate Invite with Google</Button>
          </Box>
        </VStack>
      </Flex>
    </>
  )
}
