import { User } from "firebase/auth";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import LoadingPage from "../pages/LoadingPage";
import { UserType, useUserContext } from "./UserContext";
import { computeAuthRoute } from "./utils";


export const AuthRoute: React.FC = () => {
  const { firebaseUser, user, loading, authDest, setAuthDest } = useUserContext();

  // Reset the path destination

  if (loading) {
    return <LoadingPage />
  }
  return <AuthRouteInternal firebaseUser={firebaseUser} user={user} authDest={authDest} setAuthDest={setAuthDest} />
}

type AuthRouteInternalProps = {
  firebaseUser: User | null;
  user: UserType | null;
  authDest: string;
  setAuthDest: React.Dispatch<React.SetStateAction<string>>;
};

const AuthRouteInternal: React.FC<AuthRouteInternalProps> = ({ firebaseUser, user, authDest, setAuthDest }: AuthRouteInternalProps) => {
  const { pathname } = useLocation();
  const path = computeAuthRoute(firebaseUser, user) ?? authDest;

  // TODO: Redirects shouldn't happend automatically? 
  // Only redirect when data is fully loaded.
  if (path !== pathname) {
    return <Navigate to={path} replace />
  }
  return <Outlet />;
}