import firebase from "./firebase";
import {
    where,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    deleteDoc,
    serverTimestamp,
    arrayRemove,
    arrayUnion,
    DocumentData,
    FirestoreDataConverter,
    PartialWithFieldValue,
    QueryDocumentSnapshot,
    SnapshotOptions,
    DocumentReference,
} from "firebase/firestore";
import { GenParams, Invite, Project, Prompt } from "./types";
import { Configuration, CreateCompletionResponse, OpenAIApi } from "openai";
import { updateRestTypeNode } from "typescript";
import { useUser } from "./auth/ProtectedRoute";
import { useCollectionData } from "react-firebase-hooks/firestore";

const projectsRef = collection(firebase.db, "projects");
const promptsRef = collection(firebase.db, "prompts");
const usersRef = collection(firebase.db, "users");



export type CreateInviteParams = {
    // On creation
    name?: string, // The name displayed on the invite page
    openAiApiKey: string,
}


export const inviteConverter: FirestoreDataConverter<Invite> = {
    toFirestore(invite: PartialWithFieldValue<CreateInviteParams>): DocumentData {
        return {
            name: invite.name,
            openAiApiKey: invite.openAiApiKey,
        }
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Invite {
        const data = snapshot.data(options);
        // 
        return {
            id: snapshot.id,
            name: data.name,
            openAiApiKey: data.openAiApiKey,
            claimedBy: data.claimedBy,
        };
    },
};

export type CreatePromptParams = {
    projectId: string,
    name: string,
    template: string,
    genParams?: GenParams,
}


export const promptConverter: FirestoreDataConverter<Prompt> = {
    toFirestore(prompt: PartialWithFieldValue<CreatePromptParams>): DocumentData {
        return {
            projectId: prompt.name,
            name: prompt.name,
            template: prompt.template,
        }
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Prompt {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            projectId: data.projectId,
            name: data.name,
            template: data.template,
        };
    },
};

export type CreateProjectParams = {
    uid: string;
    name: string;
}
export const projectConverter: FirestoreDataConverter<Project> = {
    toFirestore(project: PartialWithFieldValue<CreateProjectParams>): DocumentData {
        return {
            uid: project.uid,
            name: project.name,
            created: serverTimestamp(),
        }
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Project {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            uid: data.uid,
            name: data.name,
            created: (data.created?.toDate() as Date)?.toISOString()
        };
    },
};

export const getProject = async (projectId: string): Promise<Project> => {
    const projectRef = doc(projectsRef, projectId);

    const projectSnap = await getDoc(projectRef);
    if (!projectSnap.exists()) {
        throw new Error("No document for project: " + projectId);
    }

    let project = projectSnap.data() as Project;
    project.id = projectSnap.id;
    return project;
};

export const useUserProjects = () => {
    const { firebaseUser } = useUser()
    return useCollectionData(
        query(
            collection(firebase.db, "projects"),
            where("uid", "==", firebaseUser.uid)
        ).withConverter(projectConverter)
    )
}

export const createProject = async (uid: string, name: string): Promise<DocumentReference<DocumentData>> => {
    const projectRef = doc(projectsRef).withConverter(projectConverter)
    await setDoc(
        projectRef, 
        { 
            uid, 
            name
        } as Project
    );
    return projectRef
};

export const updateProject = async ({
    projectId,
    name,
    promptIds,
}: {
    projectId: string;
    name?: string;
    promptIds?: string[];
}) => {
    const docRef = doc(projectsRef, projectId);
    if (name) await updateDoc(docRef, { name });
    if (promptIds) await updateDoc(docRef, { promptIds });
};

export const getPrompts = async ({
    uid,
    projectId,
}: {
    uid: string;
    projectId: string;
}): Promise<Prompt[]> => {
    const q = query(promptsRef, where("projectId", "==", projectId), where("uid", "==", uid));
    const snap = await getDocs(q);

    const prompts: Prompt[] = [];
    snap.docs.forEach((doc) => {
        let prompt = doc.data() as Prompt;
        prompt.id = doc.id;
        prompts.push(prompt);
    });

    return prompts;
};

// Retrieve the current prompt, and update order?
export const createPrompt = async ({
    uid,
    projectId,
    name,
    template,
}: {
    uid: string;
    projectId: string;
    name: string;
    template?: string;
}): Promise<DocumentReference<DocumentData>> => {
    const newDocRef = doc(promptsRef);
    await setDoc(newDocRef, {
        uid,
        projectId,
        name,
        template: template || "",
        createdAt: serverTimestamp(),
    });
    return newDocRef
};

export const updatePrompt = async ({
    promptId,
    ...update
}: {
    promptId: string;
    name?: string;
    template?: string;
    genParams?: GenParams;
    outputs?: CreateCompletionResponse[];
}) => {
    const docRef = doc(promptsRef, promptId);
    await updateDoc(docRef, update)
};

export const deletePrompt = async (promptId: string, projectId: string) => {
    const promptDoc = doc(promptsRef, promptId)
    await deleteDoc(promptDoc)
};

export const deleteProject = async (projectId: string) => {
    const projectDoc = (await getDoc(doc(firebase.db, "projects", projectId))).data();
    const promptIds = (projectDoc?.promptIds ?? []) as string[];

    // delete all prompts in project
    const processing: Promise<void>[] = [];
    promptIds.forEach((promptId) => processing.push(deleteDoc(doc(promptsRef, promptId))));
    await Promise.all(processing);

    await deleteDoc(doc(projectsRef, projectId));
};

export const setApiKey = async (uid: string, apiKey: string) => {
    const userRef = doc(usersRef, uid);
    updateDoc(userRef, { openAiApiKey: apiKey });
};

export const verifyOpenAiApiKey = async (apiKey: string) => {
    try {
        const configuration = new Configuration({
            apiKey,
        });
        const openai = new OpenAIApi(configuration);
        await openai.retrieveModel("text-davinci-002");
        return true;
    } catch (err) {
        console.log(err);
    }
    return false;
}

export const generateOpenAiCompletion = async (
    openAiApiKey: string,
    promptText: string,
    genParams: GenParams
): Promise<CreateCompletionResponse> => {
    const configuration = new Configuration({ apiKey: openAiApiKey });
    const openai = new OpenAIApi(configuration);

    const modelResponse = await openai.retrieveModel("text-davinci-002");
    // TODO: refactor gen_params to be consistant with OpenAI params!
    const response = await openai.createCompletion({
        model: "text-davinci-002",
        prompt: promptText,
        temperature: genParams.temperature,
        max_tokens: genParams.maxLen,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
    });
    return response.data;
};
