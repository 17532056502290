import { Icon, InfoOutlineIcon } from "@chakra-ui/icons"
import { Container, Popover, PopoverTrigger, Button, PopoverContent, PopoverArrow, PopoverBody, HStack, Spacer, Divider, Text } from "@chakra-ui/react"
import { CreateCompletionResponse } from "openai"
import { FC } from "react"
import { IoMdReturnLeft } from "react-icons/io"

type OutputCardProps = {
    output: CreateCompletionResponse,
    maxTokens?: number
}

const OutputCard: FC<OutputCardProps> = ({ output, maxTokens }) => {
    const maxTokensReached = !output.usage || !maxTokens
        ? false 
        : output.usage?.total_tokens >= maxTokens
    return (
        <Container
            resize="none"
            paddingInline={2}
            border="1px"
            borderColor="lightgrey"
            borderRadius={5}
            padding={5}
            w="full"
            maxW="full"
            whiteSpace="pre-wrap"
        >
            {output.choices[0].text}
            {" "}
            {!!output.usage && 
                <Popover>
                    <PopoverTrigger>
                        <Button 
                            variant="unstyled" 
                            verticalAlign="top" 
                            height="min-content" 
                            fontWeight="normal"
                            display="inline" 
                            color={maxTokensReached ? "red.500" : "gray.500"}
                            _hover={{ color: maxTokensReached ? "red.400" : "gray.400" }}
                        >
                            <Icon as={IoMdReturnLeft} mb="-3px" fontSize="sm" display="inline" />
                            {" "}
                            <Text fontSize="xs" fontFamily="mono" display="inline">{output.usage.completion_tokens} tokens</Text>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody fontSize="sm">
                            <HStack>
                                <Text>Prompt Tokens</Text>
                                <Spacer />
                                <Text>{output.usage.prompt_tokens}</Text>
                            </HStack>
                            <HStack fontWeight="semibold" color={maxTokensReached ? "red.500" : "inherit"}>
                                <Text>Output Tokens</Text>
                                <Spacer />
                                <Text>{output.usage.completion_tokens}</Text>
                            </HStack>
                            {maxTokensReached &&
                                <HStack color="red.500">
                                    <InfoOutlineIcon />
                                    <Text>Maximum token limit reached</Text>
                                </HStack>
                            }
                            <Divider my="1" />
                            <HStack>
                                <Text>Total Tokens</Text>
                                <Spacer />
                                <Text>{output.usage.total_tokens}</Text>
                            </HStack>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            }
        </Container>
    )
}

export default OutputCard