import {
    HStack,
    Icon,
    Text,
    MenuList,
    MenuItem,
    Box,
    Editable,
    EditableInput,
    useOutsideClick,
} from "@chakra-ui/react";
import { CopyIcon, DeleteIcon, EditIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { AiOutlineFileText } from "react-icons/ai";
import PromptSelectorBase from "./PromptSelectorBase";
import { Prompt } from "../../types";
import { FC, ReactNode, useRef, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { ContextMenu, ContextMenuItemList, ContextMenuItem } from "../../components/ContextMenu";

type PromptCardProps = {
    prompt: Prompt;
    selected: boolean,
    onDuplicate: (prompt: Prompt) => void;
    onSelect: (prompt: Prompt) => void;
    onSplit: (prompt: Prompt) => void;
    onDelete: (prompt: Prompt) => void;
    onRename: (prompt: Prompt, name: string) => void
};
const PromptCard: FC<PromptCardProps> = ({
    prompt,
    selected,
    onSelect: selectPrompt,
    onSplit: splitPrompt,
    onDuplicate: duplicatePrompt,
    onDelete: deletePrompt,
    onRename
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: prompt.id });

    const renamingInputRef = useRef<HTMLInputElement>(null)
    const [renaming, setRenaming] = useState(false)
    const completeRename = () => {
        if (renamingInputRef.current?.value) {
            onRename(prompt, renamingInputRef.current?.value)
            setRenaming(false)
        }
    }
    useOutsideClick({
        ref: renamingInputRef,
        handler: completeRename
    })

    let nameContent: ReactNode
    if (renaming) {
        nameContent =
            <Editable startWithEditView defaultValue={prompt.name} onSubmit={completeRename} textAlign="start">
                <EditableInput ref={renamingInputRef} px="0.5em" />
            </Editable>
    } else {
        nameContent = <Text fontSize="sm">{prompt.name}</Text>
    }

    return (
        <Box
            ref={setNodeRef}
            style={{ marginTop: 0 }}
            w="full"
            transform={transform != null ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : ''}
            transition={transition}
            {...attributes}
            {...listeners}
        >
            <ContextMenu<HTMLDivElement>
                renderMenu={() => (
                    <ContextMenuItemList>
                        <ContextMenuItem
                            icon={<EditIcon fontSize="xs" />}
                            fontSize="xs"
                            onClick={() => setRenaming(true)}
                        >
                            Rename
                        </ContextMenuItem>
                        <ContextMenuItem
                            icon={<CopyIcon fontSize="xs" />}
                            fontSize="xs"
                            onClick={() => duplicatePrompt(prompt)}
                        >
                            Duplicate
                        </ContextMenuItem>
                        <ContextMenuItem
                            icon={<PlusSquareIcon fontSize="xs" />}
                            fontSize="xs"
                            onClick={() => splitPrompt(prompt)}
                        >
                            Split
                        </ContextMenuItem>
                        <ContextMenuItem
                            icon={<DeleteIcon fontSize="xs" />}
                            fontSize="xs"
                            onClick={() => deletePrompt(prompt)}
                        >
                            Delete
                        </ContextMenuItem>
                    </ContextMenuItemList>
                )}
            >
                {ref => (
                    <div ref={ref} style={{ width: "100%", marginTop: 0 }}>
                        <PromptSelectorBase
                            key={prompt.name}
                            variant="outline"
                            backgroundColor={selected ? "gray.100" : "inherit"}
                            _hover={{ backgroundColor: selected ? "gray.100" : "gray.50" }}
                            borderWidth="0"
                            borderRadius={0}
                            marginTop="0"
                            onClick={() => {
                                if (renaming) {
                                    return
                                }

                                selectPrompt(prompt)
                            }}
                        >
                            <HStack>
                                <Icon as={AiOutlineFileText} />
                                {nameContent}
                            </HStack>
                        </PromptSelectorBase>
                    </div>
                )}
            </ContextMenu>
        </Box>
    )
};

export default PromptCard;
