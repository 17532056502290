import { Popover, PopoverTrigger, IconButton, PopoverContent, PopoverArrow, VStack, HStack, Spacer, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Text, Icon } from "@chakra-ui/react"
import { FC } from "react"
import { VscSettings } from "react-icons/vsc"

type ParametersPopoverProps = {
    maxLen: number,
    onMaxLenUpdate: (value: number) => void,
    temperature: number,
    onTemperatureUpdate: (value: number) => void,
}

const ParametersPopover: FC<ParametersPopoverProps> = ({ maxLen, onMaxLenUpdate, temperature, onTemperatureUpdate }) => {
    return (
        <Popover>
            <PopoverTrigger>
                <IconButton aria-label="params" icon={<Icon as={VscSettings} fontSize="md" />} variant="outline" borderLeftWidth={0} size="sm" />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <VStack p={5}>
                    <HStack w="full">
                        <Text fontSize="xs">Maximum Length</Text>
                        <Spacer />
                        <NumberInput 
                            size="xs" 
                            w="30%" 
                            value={maxLen} 
                            onChange={(valueString) => onMaxLenUpdate(parseInt(valueString))} 
                            min={1}
                            max={1500}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </HStack>
                    <Slider 
                        size="sm" 
                        aria-label="max-length-slider" 
                        min={1} 
                        max={1500} 
                        value={maxLen} 
                        onChange={(value) => onMaxLenUpdate(value)}
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <HStack w="full">
                        <Text fontSize="xs">Temperature</Text>
                        <Spacer />
                        <NumberInput 
                            size="xs" 
                            step={0.01} 
                            w="30%" 
                            min={0.0} 
                            max={1.0} 
                            value={temperature} 
                            onChange={(valueString) => onTemperatureUpdate(parseInt(valueString))}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </HStack>
                    <Slider 
                        size="sm" 
                        aria-label='slider-ex-1' 
                        step={0.01} 
                        min={0.0} 
                        max={1.0} 
                        onChange={(value) => onTemperatureUpdate(value)} 
                        value={temperature}
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                </VStack>
            </PopoverContent>
        </Popover>
    )
}

export default ParametersPopover