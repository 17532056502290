import { Button, ButtonProps } from "@chakra-ui/react"
import { FC, ReactNode } from "react";

const PromptSelectorBase: FC<{ children?: ReactNode } & ButtonProps> = ({ children, ...props }) => {
    return (
        <Button
            w="full"
            h="2em"
            justifyContent="flex-start"
            variant="outline"
            fontWeight="normal"
            overflowX="hidden"
            {...props}
        >
            {children}
        </Button>
    );
};

export default PromptSelectorBase;
